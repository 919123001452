import { type FC, useEffect, useState } from 'react';
import type { WithTranslations } from '#i18n/index.ts';
import styles from './MainHeaderAuthActions.module.scss';
import ButtonLikeLinkWithAnalytics from '#components/shared/ui/Analytics/ButtonLikeLinkWithAnalytics';
import api from '#api/index.ts';
import { checkSignUpAllowed } from '#utils/geo.ts';
import Button from '#components/shared/ui/Button';
import useLocalStorage from '#hooks/useLocalStorage.ts';
import { featuresPathsMap, PRICING_FEATURE_PAGES } from '#constants/features.ts';
import type { GeoResponse } from '#types/geo.ts';
import ManualChat from '#components/core/ManualChat';
import { HOST } from 'CONFIG';
import { COMPLIANCE_HOSTS } from '#constants/compliances.ts';

const isComplianceSearqle = COMPLIANCE_HOSTS.includes(HOST);
type Props = {
  isZendeskEnabled?: boolean;
  utm?: string;
};

const MainHeaderAuthActions: FC<WithTranslations<Props>> = ({
  t,
  isZendeskEnabled = true,
  utm,
}) => {
  const [isSignUpAllowed, setIsSignUpAllowed] = useState<boolean>(false);
  const [isPricingVisible, setIsPricingVisible] = useState<boolean>(false);
  const [commercialLinkData] = useLocalStorage<object>('_cl', {});

  // fetching geolocation data
  useEffect(() => {
    const { pathname } = window.location;

    function checkSignUpPermissions(data: GeoResponse): boolean {
      const isAllowedCountry = checkSignUpAllowed(data);
      if (pathname !== `/${featuresPathsMap.vin}/`) {
        return isAllowedCountry;
      }
      // for VIN Code Lookup page – check also if link is commercial, only if it is not – show sign up
      const hasCommercialLinksData = Object.keys(commercialLinkData).length;
      return isAllowedCountry && !hasCommercialLinksData;
    }

    api.visit.geo().then(checkSignUpPermissions).then(setIsSignUpAllowed);
  }, [commercialLinkData]);

  // checking pricing is available
  useEffect(() => {
    const isPricingFeaturePage = PRICING_FEATURE_PAGES.some((path) => location.pathname === path);
    const hasCommercialLinksData = Object.keys(commercialLinkData).length;
    setIsPricingVisible(isPricingFeaturePage && !hasCommercialLinksData && isComplianceSearqle);
  }, [commercialLinkData]);

  return (
    <div className={styles.buttons}>
      {isPricingVisible && (
        <Button href="#prices" size="sm" variant="transparent" className={styles.prices}>
          {t.common.prices.title}
        </Button>
      )}
      <ButtonLikeLinkWithAnalytics
        href={`/login${utm && `?${utm}`}`}
        size="sm"
        variant="transparent"
        className={styles.login}
        event={{ action: 'login' }}
      >
        {t.common.auth.logIn}
      </ButtonLikeLinkWithAnalytics>
      {isSignUpAllowed && (
        <ButtonLikeLinkWithAnalytics
          href={`/account${utm && `?${utm}`}`}
          size="sm"
          variant="outlined"
          className={styles.signup}
          event={{ action: 'sign_up' }}
          style={{ minWidth: '75px' }}
        >
          {t.common.auth.singUp}
        </ButtonLikeLinkWithAnalytics>
      )}
      {isZendeskEnabled && <ManualChat isPlacedInHeader />}
    </div>
  );
};

export default MainHeaderAuthActions;
